import React, {Component} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Navigate } from 'react-router-dom';

class InfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            toReturn: 0,
            newEntry: 0
        }
    }

    componentDidMount() {
        if (localStorage.getItem('name') === null || localStorage.getItem('surname') === null) {
            this.setState({
                toReturn: 1
            }); 
        } else {
            this.getInfo();
            if (localStorage.getItem('newEntry') === 1) {
                this.setState({
                    newEntry: 1
                });
            }
        }
        localStorage.setItem('newEntry', 0);
    }

    toReturn = () => {
        if (this.state.toReturn === 1) {
            return <Navigate to="/form" replace={true}/>
        }
    }

    getInfo = () => {
        let nameLocal = localStorage.getItem('name');
        let surnameLocal = localStorage.getItem('surname');
        let passwordLocal = localStorage.getItem('password');
        fetch(this.props.api + "search", {
            method: "POST",
            body: JSON.stringify({name: nameLocal, surname: surnameLocal, password: passwordLocal}),
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then((response) => response.json())
        .then(
            response => {
                this.setState({
                    data: response.data
                });
                if (response.data.accepted === 0) {
                    localStorage.removeItem('name');
                    localStorage.removeItem('surname');
                    localStorage.removeItem('password');
                    localStorage.removeItem('newEntry');
                    this.setState({
                        toReturn: 1
                    });
                }
            },
            error => {
                console.log(error);
                alert("Wystąpił błąd! Spróbuj odświeżyć stronę. Jeżeli to nie pomoże, poinformuj o problemie Państwa Młodych.");
            }
            );
    }

    checkIfFromForm = () => {
        if (this.state.newEntry === 1) {
            return <h1>Dziękujemy za złożenie deklaracji!</h1>
        } else {
            return <h1>Złożono deklarację</h1>
        }
    }

    chooseWord = () => {
        if (this.state.data.multi_invitation === 1) {
            return "Waszej"
        } else {
            return "Twojej"
        }
    }

    readDeclaration = () => {
        if (this.state.data.accepted === 1 && this.state.data.multi_invitation === 0) {
            return "Przyjdę";
        } else if (this.state.data.accepted === 1 && this.state.data.multi_invitation === 1) {
            return "Przyjdziemy";
        } else if (this.state.data.accepted === 2 && this.state.data.multi_invitation === 0) {
            return "Nie przyjdę";
        } else if (this.state.data.accepted === 2 && this.state.data.multi_invitation === 1) {
            return "Nie przyjdziemy";
        }
    }

    render() {
        return <>
        {this.toReturn()}
         <Container fixed>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: '50px',
                marginBottom: '50px',
                alignItems: 'center'
            }}
            >
                {this.checkIfFromForm()}
                <b><p>Oto status {this.chooseWord()} informacji zawartej w bazie: </p></b>
                <p>Deklaracja: {this.readDeclaration()}</p>
                <p>Uwagi do deklaracji: {this.state.data.acceptation_info}</p>
                <br/>
                <p>Chcesz zmienić deklarację? Prosimy o kontakt telefoniczny z Asią lub Kryspinem.</p>
                <br/><p>Pamiętaj, by zapisać link do tej aplikacji - jeszcze się przyda przed i na weselu!</p>
            </Box>
        </Container>
        </>
    }
}

export {InfoPage};