import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { getToken2, onMessageListener } from '../firebase';
import { Signpost } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function checkIfAlone(flag) {
    if (flag === 0) {
        return "Witaj! 👋";
    } else {
        return "Witajcie! 👋";
    }
}

function returnGoodForm(data) {
    if (data.multi_invitation === 0) {
        localStorage.setItem('guestInfo', "Jesteście zalogowani jako " + data.name + " " + data.surname);
        return "Jesteś zalogowany jako " + data.name + " " + data.surname;
    } else {
        if (checkIfAccompanying(data) === 1) {
            localStorage.setItem('guestInfo', "Jesteście zalogowani jako " + data.name + "" + data.surname + " z osobą towarzyszącą");
            return "Jesteście zalogowani jako " + data.name + " " + data.surname + " z osobą towarzyszącą";
        } else {
            if (checkIfIsTheSameSurname(data) === 1) {
                let info = "Jesteście zalogowani jako " + data.name
                let length = data.multi.length;
                let i = 1;
                data.multi.forEach(guest => {
                    if (i === length) {
                        info += " i " + guest.name;
                    } else {
                        info += ", " + guest.name;
                    }
                })
                info += " " + data.surname;
                localStorage.setItem('guestInfo', info);
                return info
            } else {
                let info = "Jesteście zalogowani jako " + data.name + " " + data.surname;
                let length = data.multi.length;
                let i = 1;
                data.multi.forEach(guest => {
                    if (i === length) {
                        info += " i " + guest.name + " " + guest.surname;
                    } else {
                        info += ", " + guest.name + " " + guest.surname;
                    }
                })
                localStorage.setItem('guestInfo', info);
                return info;
            }
        }
    }
}

function checkIfAccompanying (data) {
    let isAccompanying = 0;
    data.multi.forEach(guest => {
        if (guest.name === "Osoba" && guest.surname === "towarzysząca") {
            isAccompanying = 1;
        }
    });
    return isAccompanying;
}

function checkIfIsTheSameSurname (data) {
    let isTheSame = 1;
    data.multi.forEach(guest => {
        if (guest.surname !== data.surname) {
            isTheSame = 0;
        }
    });
    return isTheSame;
}

function returnMultiGuestPlaceData (data) {
    console.log(data);
    return data.map(guest => (
            <p key={guest.id}>
            {guest.name} - miejsce nr {guest.place_number}<br/>
            <i>{guest.place_description}</i>
            </p>
        )
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MainPage(props) {
    if(localStorage.getItem('name') === null || localStorage.getItem('password') === null) {        
        localStorage.removeItem('name');
        localStorage.removeItem('surname');
        localStorage.removeItem('password');
        window.location.href = "/login";
    }

    const [guestData, saveGuestData] = React.useState([])
    const [isLoaded, changeStatus] = React.useState(false);
    const guestName = localStorage.getItem('name');
    const guestSurname = localStorage.getItem('surname');
    const guestPassword = localStorage.getItem('password');

    const loginData = {
        name: guestName,
        surname: guestSurname,
        password: guestPassword
    }
    const loginDataJSON = JSON.stringify(loginData);
    
    React.useEffect(() => {
        if (!isLoaded) {
            let data = localStorage.getItem("stat_data");
            if (data === null) {
                localStorage.removeItem('name');
                localStorage.removeItem('surname');
                localStorage.removeItem('password');
                window.location.href = "/login";
            }
            fetch(props.api + "loginguest", {
                method: "POST",
                body: loginDataJSON,
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .then(response => {
                if (response.data.name === 'error') {
                    localStorage.removeItem('name');
                    localStorage.removeItem('surname');
                    localStorage.removeItem('password');
                    window.location.href = "/login";
                } else {
                    saveGuestData(response.data);
                    changeStatus(true);
                    
                    
                    
                }
                },
                error => {
                    console.log(error);
                    alert ("Wystąpił błąd w kontakcie z serwerem. Odśwież stronę, a jeżeli problem się powtórzy, zgłoś to Parze Młodej.");
                }
            );
        }
    });
    const [isTokenFound, setTokenFound] = React.useState(false);
    getToken2(setTokenFound);
    
    const [openFirstDialog, setOpenFirstDialog] = React.useState(false);

    const handleFirstWidgetButtonClick = () => {
        setOpenFirstDialog(true);
    }
    const handleFirstDialogClose = () => {
        setOpenFirstDialog(false);
    }

    const [openWeddingMapDialog, setOpenWeddingMapDialog] = React.useState(false);

    const handleWeddingMapWidgetButtonClick = () => {
        setOpenWeddingMapDialog(true);
    }
    const handleWeddingMapDialogClose = () => {
        setOpenWeddingMapDialog(false);
    }

    

    const [openGotNotificationDialog, setOpenGotNotificationDialog] = React.useState(false);

    const handleGotNotificationButtonClick = () => {
        setOpenGotNotificationDialog(true);
    }
    const handleGotNotificationDialogClose = () => {
        setOpenGotNotificationDialog(false);
    }

    const [openWeddingParkingDialog, setOpenWeddingParkingDialog] = React.useState(false);

    const handleWeddingParkingWidgetButtonClick = () => {
        setOpenWeddingParkingDialog(true);
    }
    const handleWeddingParkingDialogClose = () => {
        setOpenWeddingParkingDialog(false);
    }
    const [openTableModelDialog, setOpenTableModelDialog] = React.useState(false);

    const handleTableModelButtonClick = () => {
        setOpenTableModelDialog(true);
        fetch(props.api + "placesstats", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
        })
         .then(res => res.json())
         .then(response => {
            localStorage.setItem("places_stat_data", response);
         })
    }
    const handleTableModelDialogClose = () => {
        setOpenTableModelDialog(false);
    }

    

    const [notification, setNotification] = React.useState({title: '', body: ''});

    onMessageListener().then(payload => {
        handleGotNotificationButtonClick();
        setNotification({title: payload.notification.title, body: payload.notification.body})
        console.log(payload);
      }).catch(err => console.log('failed: ', err));

      const navigate = useNavigate();

    if (!isLoaded) {
        return (<Container fixed>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100vw',
                height: '100vh',
                marginTop: '50px',
                marginBottom: '50px',
                alignItems: 'center'
            }}
            >
                <CircularProgress />
                <p><center>Ładujemy dane, poczekaj chwilę...</center></p>
            </Box>
        </Container>
        );
    } else {
        return (
            <Container fixed sx={{width: '95vw'}}>
                <Dialog
                    open={openGotNotificationDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleGotNotificationDialogClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{notification.title}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {notification.body}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleGotNotificationDialogClose}>Zamknij</Button>
                    </DialogActions>
                </Dialog>
                <h1>{checkIfAlone(guestData.multi_invitation)}</h1>
                <p>{returnGoodForm(guestData)}</p>
                <p>Pobrano złe dane? <Button variant="text" onClick={() => {
                    localStorage.removeItem('name');
                    localStorage.removeItem('surname');
                    localStorage.removeItem('password');
                    window.location.href = "/login";
                }}>Wyloguj się</Button></p>
                <Box sx={{ minWidth: 275 }}>
                    <Card variant="outlined" sx={{backgroundColor: 'rgba(255, 255, 255, 0.5)', textAlign: 'left', justifyContent: 'left', alignItems: 'left'}}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Ślub
                            </Typography>
                            <Typography variant="body2">
                                Uroczystość zaślubin odbędzie się 24 sierpnia 2024 roku o godzinie 17:00 
                                w kościele pw. św. Marcina i św. Marii Magdaleny w Górce Pabianickiej.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" sx={{marginLeft: 'auto'}} onClick={handleFirstWidgetButtonClick}>Pokaż na mapie</Button>
                        </CardActions>
                    </Card>
                    <Dialog
                    open={openFirstDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleFirstDialogClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Lokalizacja miejsca"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Wskazuję na mapie miejsce: <b>Kościół pw. św. Marcina i św. Marii Magdaleny w Górce Pabianickiej</b>
                        <br/><br/>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9891.356414852364!2d19.3260071!3d51.6993894!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471a49f86d3527a1%3A0xbddd6ddd2fa9452d!2zS2_Fm2Npw7PFgiBwdy4gxZt3LiBNYXJjaW5hIGkgxZt3LiBNYXJpaSBNYWdkYWxlbnk!5e0!3m2!1spl!2spl!4v1714753924419!5m2!1spl!2spl" width="400" height="300" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="churchLocation"></iframe>
                        <br/><br/>
                        <i>Kliknij <b>Wyświetl większą mapę</b> by uzyskać możliwość uruchomienia nawigacji.</i><br/>
                        <i>Dostawca mapy: <a href='https://maps.google.com'>Google Maps</a></i>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFirstDialogClose}>Zamknij</Button>
                    </DialogActions>
                </Dialog>
                </Box>
                <br/>
                <Box sx={{ minWidth: 275 }}>
                    <Card variant="outlined" sx={{backgroundColor: 'rgba(255, 255, 255, 0.5)', textAlign: 'left', justifyContent: 'left', alignItems: 'left'}}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Wesele
                            </Typography>
                            <Typography variant="body2">
                                Na przyjęcie weselne zapraszamy po uroczystości zaślubin 
                                do restauracji <i>Willa Impresja</i> przy ul. Żeromskiego 20a w Pabianicach
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" sx={{marginLeft: 'auto'}} onClick={handleWeddingParkingWidgetButtonClick}>Parking</Button>
                            <Button size="small" sx={{marginLeft: 'auto'}} onClick={handleWeddingMapWidgetButtonClick}>Pokaż na mapie</Button>
                        </CardActions>
                    </Card>
                    <Dialog
                    open={openWeddingMapDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleWeddingMapDialogClose}
                    aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{"Lokalizacja miejsca"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Wskazuję na mapie miejsce: <b>Restauracja <i>Willa Impresja</i>, ul. Żeromskiego 20a, Pabianice</b>
                            <br/><br/>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2474.469907555001!2d19.346307170675747!3d51.669541186321176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471a363eb51a92f9%3A0xe0233cd0f9b352f5!2sWilla%20Impresja!5e0!3m2!1spl!2spl!4v1714834164141!5m2!1spl!2spl" width="400" height="300" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="weddingPlaceLocation"></iframe>
                            <br/><br/>
                            <i>Kliknij <b>Wyświetl większą mapę</b> by uzyskać możliwość uruchomienia nawigacji.</i><br/>
                            <i>Dostawca mapy: <a href='https://maps.google.com'>Google Maps</a></i>
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleWeddingMapDialogClose}>Zamknij</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                    open={openWeddingParkingDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleWeddingParkingDialogClose}
                    aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{"Parkowanie"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Przy restauracji mieści się parking dla gości, na którym można pozostawić swój pojazd. Jest on dość duży, tak więc wszyscy GOście powinni się zmieścić.
                            <br/><br/>
                            Podrzucamy także listę pobliskich opcji alternatywnych, z których można skorzystać:
                            <br/><br/>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <ListItem>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <LocalPoliceIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Komenda Powiatowa Policji" secondary="ul. Żeromskiego, 160 m od restauracji" />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <Signpost />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="ul. Chłodna" secondary="skręt z ul. Żeromskiego przy restauracji" />
                                </ListItem>
                                </List>
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleWeddingParkingDialogClose}>Zamknij</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                <br/>
                <Box sx={{ minWidth: 275 }}>
                    <Card variant="outlined" sx={{backgroundColor: 'rgba(255, 255, 255, 0.5)', textAlign: 'left', justifyContent: 'left', alignItems: 'left'}}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Zlokalizuj się!
                            </Typography>
                            <Typography variant="body2">
                                Nie stój w kolejce do tablicy gości! Kliknij <i>Pokaż</i> - zostanie wyświetlona lokalizacja miejsc przy stole na modelu.<br/>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" sx={{marginLeft: 'auto'}} onClick={handleTableModelButtonClick}>Pokaż</Button>
                        </CardActions>
                    </Card>
                    <Dialog
                    open={openTableModelDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleTableModelDialogClose}
                    aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{"Lokalizacja miejsca przy stole"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Zapraszamy Was do stołu nr. {guestData.table_number}!<br/>
                            Wasze miejsca: <br/>
                            {guestData.name} - miejsce nr {guestData.place_number} <br/>
                            <i>{guestData.place_description}</i>
                            {returnMultiGuestPlaceData(guestData.multi)}
                            <br/><br/>
                            <i>Miejsca liczymy od stołu prezydialnego, najpierw lewa strona od wejścia, potem prawa.</i><br/><br/>
                            Wskazuję model stołu z zaznaczonym rejonem podanych miejsc
                            <br/><br/>
                            <img src={guestData.model_link} alt="Model stołu nie został poprawnie wczytany" style={{width: '100%', height: '100%'}}/>
                            <br/><br/>
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleTableModelDialogClose}>Zamknij</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                <br/>
                <Box sx={{ minWidth: 275 }}>
                    <Card variant="outlined" sx={{backgroundColor: 'rgba(255, 255, 255, 0.5)', textAlign: 'left', justifyContent: 'left', alignItems: 'left'}}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Jak się bawicie?
                            </Typography>
                            <Typography variant="body2">
                                Dołącz do albumu, prześlij swoją relację z wesela i zobacz jak imprezują inni!
                                <br/><br/>
                                <i>Korzystamy z usługi Google Photos. Do poprawnego działania funkcji dodawania własnych zdjęć wymagane jest konto Google. Zalecamy otworzenie i dołączenie do albumu w aplikacji na Androida lub iOS.</i>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" sx={{marginLeft: 'auto'}} onClick={() => {
                                fetch(props.api + "gallerystats", {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                })
                                 .then(res => res.json())
                                 .then(response => {
                                    localStorage.setItem("gallery_stat_data", response);
                                    window.location.href = "https://photos.app.goo.gl/MXmmdaaCi43gL7RDA";
                                 });
                            }}>Przejdź do albumu</Button>
                        </CardActions>
                    </Card>
                </Box>
                <br/>
                <Box sx={{ minWidth: 275 }}>
                    <Card variant="outlined" sx={{backgroundColor: 'rgba(255, 255, 255, 0.5)', textAlign: 'left', justifyContent: 'left', alignItems: 'left'}}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Księga gości
                            </Typography>
                            <Typography variant="body2">
                                Przejdź do wirtualnej księgi gości - zapełnianej przez Was mnóstwem miłych słów i wspomnień!
                                <br/>
                                Wpisz się bądź przeczytaj to, co pozostawili inni. Zapraszamy!
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" sx={{marginLeft: 'auto'}} onClick={() => {
                                fetch(props.api + "guestbookstats", {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                })
                                 .then(res => res.json())
                                 .then(response => {
                                    localStorage.setItem("guest_book_stat_data", response);
                                    navigate('/guestbook');
                                 });
                            }}>Przejdź</Button>
                        </CardActions>
                    </Card>
                </Box>
                <br/>
                <Box sx={{ minWidth: 275 }}>
                    <Card variant="outlined" sx={{backgroundColor: 'rgba(255, 255, 255, 0.5)', textAlign: 'left', justifyContent: 'left', alignItems: 'left'}}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Poprawiny
                            </Typography>
                            <Typography variant="body2">
                                Na poprawiny zapraszamy serdecznie wszystkich Gości w niedzielę na godzinę 14:30.
                            </Typography>
                        </CardContent>
                    </Card>
                </Box><br/>
            </Container>
        );
    }
}

export {MainPage};