import React, {Component} from 'react';
import {Formik, Form, Field} from 'formik';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { FormLabel } from '@mui/material';
import { Navigate } from 'react-router-dom';

class AcceptForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visibleName: "",
            multi: [],
            saved: 0,
            errorTheSame: 0,
            errorDifferent: 0,
            response: [],
            accepted: 1,
            buttonText: "Wyszukaj"
        };
    }

    componentDidMount() {
        if (localStorage.getItem('name') !== null && localStorage.getItem('surname') !== null) {
            this.setState({
                saved: 1
            }); 
        }
    }

    searchGuest = () => {
        if (this.state.data.id !== undefined && this.state.data.multi_invitation === 0) {
            return <><p><b>Dziękujemy!</b> Składasz aktualnie deklarację jako {this.setVisibleName()}</p></>
        } else if (this.state.data.id !== undefined && this.state.data.multi_invitation === 1) {
            return <><p><b>Dziękujemy!</b> Składacie aktualnie deklarację jako {this.setVisibleName()}</p></>
        } else {
            return <>
            <p>Na początek podaj imię i nazwisko, Twoje lub jednej z osób ujętej w zaproszeniu, które otrzymałeś/aś.</p>
            <Formik
                    initialValues={{
                        name: "",
                        surname: "",
                        password: ""
                    }}
                    onSubmit={(values) => {
                        this.setState({buttonText: "Wyszukiwanie"});
                        fetch(this.props.api + "search", {
                            method: "POST",
                            body: JSON.stringify(values),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                         .then((response) => response.json())
                         .then(
                            response => {
                                if (response.data.name === "error") {
                                    alert ("Nie znaleziono Ciebie na żadnym zaproszeniu bądź wprowadziłeś nieprawidłowe hasło! Sprawdź dane wprowadzone do formularza. Być może wprowadziłeś je nieprawidłowo? Jeżeli wszystko jest poprawnie, a system nadal nie działa, prosimy o kontakt z Parą Młodą.");
                                    this.setState({buttonText: "Wyszukaj"});
                                } else {
                                    this.setState({
                                        data: response.data,
                                        multi: response.data.multi,
                                        buttonText: "Zapisz"
                                    });
                                    if (response.data.accepted !== 0) {
                                        localStorage.setItem('name', response.data.name);
                                        localStorage.setItem('surname', response.data.surname);
                                        localStorage.setItem('newEntry', 0);
                                        localStorage.setItem('password', response.data.password)
                                        this.setState({
                                            saved: 1
                                        });
                                    }
                                }
                                
                            },
                            error => {
                                console.log(error);
                                alert("Wystąpił błąd! Spróbuj odświeżyć stronę. Jeżeli to nie pomoże, poinformuj o problemie Państwa Młodych.");
                            }
                            );
                    }}
                >
                    <Form style={{display: 'flex', gap: '20px', justifyContent: 'center', flexDirection: 'column',}}>
                        <Field as={TextField} name="name" id="name" label="Imię" variant="outlined" style={{width: '300px'}} />
                        <Field as={TextField} name="surname" id="surname" label="Nazwisko" variant="outlined" style={{width: '300px'}} />
                        <Field as={TextField} type="password" name="password" id="password" label="Hasło" variant="outlined" style={{width: '300px'}} />
                        
                        <Button type="submit" variant="contained" style={{width: '300px'}} disabled={this.state.buttonText === "Wyszukiwanie"}>{this.state.buttonText}</Button>
                    </Form>
                </Formik>
            </>
        }
    }

    setVisibleName = () => {
        let visibleName;
        if (this.state.data.multi_invitation === 0) {
            visibleName = this.state.data.name + " " + this.state.data.surname;
        }
        else {
            if (this.sprawdzCzyTowarzyszaca() === 1) {
                visibleName = this.state.data.name + " " + this.state.data.surname + " z osobą towarzyszącą.";
            } else {
                if (this.sprawdzCzyToSamoNazwisko() === 1) {
                    visibleName = this.wypiszImiona();
                } else {
                    visibleName = this.wypiszImionaINazwiska();
                }
            }
        }
        return visibleName;
    }

    sprawdzCzyTowarzyszaca = () => {
        let jestTowarzyszaca = 0;
            this.state.multi.forEach(guest => {
                if (guest.name === "Osoba" && guest.surname === "towarzysząca") {
                    jestTowarzyszaca = 1;
                }
            })
        return jestTowarzyszaca;
    }

    sprawdzCzyToSamoNazwisko = () => {
        let jestToSamo = 1;
            this.state.multi.forEach(guest => {
                if (guest.surname !== this.state.data.surname) {
                    jestToSamo = 0;
                }
            })
        return jestToSamo;
    }

    wypiszImiona = () => {
        let i = 1;
        let dlugosc = this.state.multi.length;
        let setName = this.state.data.name;
        this.state.multi.forEach(guest => {
            if (i < dlugosc) {
                setName += ", " + guest.name;
            } else {
                setName += " i " + guest.name;
            }
            i += 1;
        })
        setName += " " + this.state.data.surname;
        return setName;
    }

    wypiszImionaINazwiska = () => {
        let i = 1;
        let dlugosc = this.state.multi.length;
        let setName = this.state.data.name + " " + this.state.data.surname;
        this.state.multi.forEach(guest => {
            if (i < dlugosc) {
                setName += ", " + guest.name + " " + guest.surname;
            } else {
                setName += " i " + guest.name + " " + guest.surname;
            }
            i += 1;
        })
        return setName;
    }

    handleChange = (event, value) => {
        if (value !== undefined) {
            console.log(value);
        }
        this.setState({
            accepted: value
        });

    }

    infoForm = () => {
        if (this.state.data.multi_invitation === 1) {
            var jestTowarzyszaca = this.sprawdzCzyTowarzyszaca();
        }
        if (this.state.data.id !== undefined && this.state.data.multi_invitation === 0) {
            return <>
                <Formik
                    initialValues={{
                        acceptation_info: "",
                        id: this.state.data.id
                    }}
                    onSubmit={(values) => {
                        this.setState({buttonText: "Zapisywanie"});
                        if (values.acceptation_info === "") {
                            values.acceptation_info = "Brak dodatkowych informacji";
                        }
                        values.accepted = this.state.accepted;
                        fetch(this.props.api + "confirm", {
                            method: "PUT",
                            body: JSON.stringify(values),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                         .then((response) => response.json())
                         .then(
                            response => {
                                this.setState({
                                    saved: 1,
                                    response: response
                                });
                                
                                localStorage.setItem('name', this.state.data.name);
                                localStorage.setItem('surname', this.state.data.surname);
                                localStorage.setItem('password', this.state.data.password);
                                localStorage.setItem('newEntry', 1);
                            },
                            error => {
                                console.log(error);
                                alert("Wystąpił błąd! Spróbuj odświeżyć stronę. Jeżeli to nie pomoże, poinformuj o problemie Państwa Młodych.");
                            }
                            );
                    }}
                >
                    <Form style={{display: 'flex', gap: '20px', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                        <FormControl>
                            <FormLabel id="accepted-label">Czy przyjdziesz na wesele?</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="accepted-label"
                                name="accepted"
                                defaultValue="1"
                                onChange={this.handleChange}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Przyjdę" />
                                <FormControlLabel value="2" control={<Radio />} label="Nie przyjdę" />
                            </RadioGroup>
                        </FormControl>
                        <FormLabel id="acceptation_info">Pudełko na wiadomości: tutaj wpisz cokolwiek, o czym chciałbyś/chciałabyś poinformować Państwa Młodych.</FormLabel>
                        <Field as={TextField} name="acceptation_info" id="acceptation_info" variant="outlined" style={{width: '300px'}} />
                        <Button type="submit" variant="contained" style={{width: '300px'}} disabled={this.state.buttonText === "Zapisywanie"}>{this.state.buttonText}</Button>
                    </Form>
                </Formik>
            </>
        } else if (this.state.data.id !== undefined && this.state.data.multi_invitation === 1 && jestTowarzyszaca === 0){
            return <>
                <Formik
                    initialValues={{
                        accepted: 1,
                        acceptation_info: "",
                        id: this.state.data.id
                    }}
                    onSubmit={(values) => {
                        this.setState({buttonText: "Zapisywanie"});
                        if (values.acceptation_info === "") {
                            values.acceptation_info = "Brak dodatkowych informacji";
                        }
                        values.accepted = this.state.accepted;
                        fetch(this.props.api + "confirm", {
                            method: "PUT",
                            body: JSON.stringify(values),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                         .then((response) => response.json())
                         .then(
                            response => {
                                this.setState({
                                    saved: 1,
                                    response: response
                                });
                                
                                localStorage.setItem('name', this.state.data.name);
                                localStorage.setItem('surname', this.state.data.surname);
                                localStorage.setItem('password', this.state.data.password);
                                localStorage.setItem('newEntry', 1);
                            },
                            error => {
                                console.log(error);
                                alert("Wystąpił błąd! Spróbuj odświeżyć stronę. Jeżeli to nie pomoże, poinformuj o problemie Państwa Młodych.");
                            }
                            );
                    }}
                >
                    <Form style={{display: 'flex', gap: '20px', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                        <FormControl>
                            <FormLabel id="accepted-label">Czy przyjdziecie na wesele?</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="accepted-label"
                                name="accepted"
                                defaultValue="1"
                                onChange={this.handleChange}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Przyjdziemy" />
                                <FormControlLabel value="2" control={<Radio />} label="Nie przyjdziemy" />
                            </RadioGroup>
                        </FormControl>
                        <FormLabel id="acceptation_info">Pudełko na wiadomości: tutaj wpisz cokolwiek, o czym chciałbyś/chciałabyś poinformować Państwa Młodych.</FormLabel>
                        <Field as={TextField} name="acceptation_info" id="acceptation_info" variant="outlined" style={{width: '300px'}} />
                        <Button type="submit" variant="contained" style={{width: '300px'}} disabled={this.state.buttonText === "Zapisywanie"}>{this.state.buttonText}</Button>
                    </Form>
                </Formik>
            </>
        } else if (this.state.data.id !== undefined && this.state.data.multi_invitation === 1 && jestTowarzyszaca === 1){
            return <>
                <Formik
                    initialValues={{
                        accepted: 1,
                        acceptation_info: "",
                        id: this.state.data.id
                    }}
                    onSubmit={(values) => {
                        this.setState({buttonText: "Zapisywanie"});
                        if (values.acceptation_info === "") {
                            values.acceptation_info = "Brak dodatkowych informacji";
                        }
                        values.accepted = this.state.accepted;
                        fetch(this.props.api + "confirm", {
                            method: "PUT",
                            body: JSON.stringify(values),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                         .then((response) => response.json())
                         .then(
                            response => {
                                this.setState({
                                    saved: 1,
                                    response: response
                                });
                                
                                localStorage.setItem('name', this.state.data.name);
                                localStorage.setItem('surname', this.state.data.surname);
                                localStorage.setItem('password', this.state.data.password);
                                localStorage.setItem('newEntry', 1);
                            },
                            error => {
                                console.log(error);
                                alert("Wystąpił błąd! Spróbuj odświeżyć stronę. Jeżeli to nie pomoże, poinformuj o problemie Państwa Młodych.");
                            }
                            );
                    }}
                >
                    <Form style={{display: 'flex', gap: '20px', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                        <FormControl>
                            <FormLabel id="accepted-label">Czy przyjdziecie na wesele?</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="accepted-label"
                                name="accepted"
                                defaultValue="1"
                                onChange={this.handleChange}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Przyjdziemy" />
                                <FormControlLabel value="2" control={<Radio />} label="Nie przyjdziemy" />
                            </RadioGroup>
                        </FormControl>
                        <FormLabel id="acceptation_info">Pudełko na wiadomości: tutaj wpisz cokolwiek, o czym chciałbyś/chciałabyś poinformować Państwa Młodych.</FormLabel>
                        <Field as={TextField} name="acceptation_info" id="acceptation_info" variant="outlined" style={{width: '300px'}} />
                        <Button type="submit" variant="contained" style={{width: '300px'}} disabled={this.state.buttonText === "Zapisywanie"}>{this.state.buttonText}</Button>
                    </Form>
                </Formik>
            </>
        }
    }

    save = () => {
        if (this.state.saved === 1) {
            return <Navigate to="/status" replace={true}/>
        }
    }

    render () {
        return <Container fixed>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: '50px',
                marginBottom: '50px',
                alignItems: 'center'
            }}
            >
                <h1>Witamy!</h1>
                <p>Drodzy Goście!</p>
                <p>Prosimy o potwierdzenie przybycia na wesele do dnia 1 lipca 2024 r. Zachęcamy do zrobienia tego poprzez ten formularz.</p>
                <p>Zaczynamy?</p>
                {this.searchGuest()}
                <br/>
                {this.infoForm()}
                {this.save()}
            </Box>
        </Container>
    }
}

export {AcceptForm};